.ct-menu {
    cursor: pointer;
    padding: 8px;
    border-left: 4px solid white;
    margin-right: 2px;
}

.ct-menu-selected {
    background-color: #f3f7ff;
    border-left: 4px solid #0069ff;
    font-weight: 500;
}

.ct-menu:hover {
    background-color: #d4def1;
    border-left: 4px solid #0069ff;
    font-weight: 500;
}