body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contact-form {
  width: 810px;
  margin: auto;
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: 28px;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
}

.header {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  height: 50px;
  text-align: center;
}

.logoCso {
  padding: 20px;
  background-color: white;
  border-radius: 18px;
  border: 1px solid #dbdbdb;
}

.button-container {
  margin-bottom: 20px;
}

.button-link {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  padding: 8px;
  border-radius: 7px;
}

.button-link:hover {
  color: rgb(230, 230, 230);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.container-home {
  max-width: 1280px;
  margin: auto;
  margin-top: 17px;
  border: 1px solid #d7d7d7;
  padding: 24px;
  border-radius: 6px;
}